import React from 'react';

import {
  DiscordIcon,
  DocumentIcon,
  FaqIcon,
  RequestFeatureSolidIcon,
  RocketIcon,
  TwitterIcon,
  WalletIcon,
} from '../../../ui/Icons';
import ExternalLink from '../../../ui/Link/ExternalLink';
import { EVENT_NAME, logEvent } from '../../../utils/amplitude';
import { routes } from '../../../utils/routes';
import { containerClass, linkClass, linkIconClass, listClass, titleClass } from './index.css';

const links = [
  {
    title: 'Getting Started',
    Icon: RocketIcon,
    url: routes.HELP_GETTING_STARTED,
    eventName: EVENT_NAME.CLICK_HELP_GETTING_STARTED,
  },
  {
    title: 'FAQs',
    Icon: FaqIcon,
    url: routes.HELP_FAQ,
    eventName: EVENT_NAME.CLICK_HELP_FAQ,
  },
  {
    title: 'Request a feature',
    Icon: RequestFeatureSolidIcon,
    url: routes.REQUEST_FEATURE,
    eventName: EVENT_NAME.CLICK_HELP_REQUEST_FEATURE,
  },
  {
    title: 'Wallets',
    Icon: WalletIcon,
    url: routes.HELP_WALLETS,
    eventName: EVENT_NAME.CLICK_HELP_WALLETS,
  },
  {
    title: 'Discord',
    Icon: DiscordIcon,
    url: routes.DISCORD,
    eventName: EVENT_NAME.CLICK_HELP_DISCORD,
  },
  {
    title: 'x.com',
    Icon: TwitterIcon,
    url: routes.TWITTER,
    eventName: EVENT_NAME.CLICK_HELP_TWITTER,
  },
  {
    title: 'Blog',
    Icon: DocumentIcon,
    url: routes.BLOG,
    eventName: EVENT_NAME.CLICK_HELP_BLOG,
  },
];

const LandingHelp = () => (
  <div className={containerClass}>
    <h5 className={titleClass}>Need help with something?</h5>
    <div className={listClass}>
      {links.map(({ title, Icon, url, eventName }) => (
        <ExternalLink
          className={linkClass}
          href={url}
          key={title}
          onClick={() => logEvent({ name: eventName })}
        >
          <Icon className={linkIconClass} />
          <span>{title}</span>
        </ExternalLink>
      ))}
    </div>
  </div>
);

export default React.memo(LandingHelp);
